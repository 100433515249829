<template>
	<!-- 订单派车详情 -->
	<div class="OrderSendCarDetail" :class="themeClass">
		<!-- <div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div> -->
		<div class="flex-row margin-t-20" >
			<div class="orderID common-top BorderRadius"  >
				<div class="orderID-title flex-row-space-between margin-t-20 font-size14">
					<div class="id"><span >订单号：</span>{{ orderDetails.fOrderNumber }}</div>
					<div class="time"><span >截止日期：</span>{{ orderDetails.fEndDate | formatDate }}</div>
				</div>
				<ul class="orderID-content flex-space-around padding-lr-15 ">
					<li class="text-center">
						<div class="font-size20 font-weight700">{{ orderDetails.fPlanAmount }}</div>
						<div class="font-size14 margin-t-5">订单量</div>
					</li>
					<li class="text-center">
						<div class="font-size20 font-weight700">{{ orderDetails.fScheduleVanAmount }}</div>
						<div class="font-size14 margin-t-5">已派车量</div>
					</li>
					<li class="text-center">
						<div class="font-size20 font-weight700">{{orderDetails.fReceivedAmount}}</div>
						<div class="font-size14 margin-t-5">提货量</div>
					</li>
					<li class="text-center">
						<div class="font-size20 font-weight700">{{orderDetails.Capac}}</div>
						<div class="font-size14 margin-t-5">可派车量</div>
					</li>
				</ul>
			</div>
			<!-- <div class="orderInfo common-top1 margin-l-10 flex-row-space-between BorderRadius"  >
				<div class="orderInfo-left">
					<div class="orderInfo-title font-size14 margin-t-15" style="font-weight: bold;">订单信息</div>
					<div class="font-size13 font-color-333">
						<div class="flex-row">
							<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
								<div>商</div>
								<div>品：</div>
							</div>
						    <div class="margin-b-5 width-505 hide-text">
						    	{{ orderDetails.fGoodsFullName }}
						    </div>
						</div>
						<div class="flex-row">
							<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
								<div>供</div>
								<div>应</div>
								<div>商：</div>
							</div>
						    <div class="margin-b-5 width-505 hide-text">
						    	{{orderDetails.supplier}}
						    </div>
						</div>
						<div class="flex-row">
							<div class="margin-b-5  hide-text" style="font-weight: bold;width: 70px;display: flex; justify-content: space-between;">
								<div>承</div>
								<div>运</div>
								<div>商：</div>
							</div>
						    <div class="margin-b-5 width-505 hide-text">
						    	{{orderDetails.fCarrierName}}
						    </div>
						</div>
					</div>
				</div>

			</div> -->
		</div>
    <div class="common-bottom flex-row" >
      <div class="id margin-b-10" style="min-width:50%"><span >商品：</span>{{ orderDetails.fGoodsFullName }}</div>
      <div class="id" style="min-width:50%"><span >供应商：</span>{{ orderDetails.fGoodsFullName }}</div>
      <div class="id" style="min-width:50%"><span >承运商：</span>{{ orderDetails.fCarrierName }}</div>
      <div class="id" style="min-width:50%"><span >工程：</span>{{ orderDetails.fProjectName }}</div>
    </div>
		<send-car-list v-model="list" @queryList="queryListData"></send-car-list>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import sendCarList from '../../../components/business/sendCarList.vue';
	import breadcrumb from "@/components/breadcrumb.vue";

	export default {
		name: "",
		components: {
			sendCarList,
			breadcrumb
		},
		props: {},
		data() {
			return {
				isDetail: true,
				// 订单ID
				orderID: null,
				// 订单信息
				orderDetails: {},
				// 派车信息
				list: [],
				// 当前页数
				currentPage: 1,
				// 每页条数
				pagesize: 5,
				// 总条数
				total: 0
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.orderID = this.$route.query.id;
			// 获取订单信息
			this.getOrderDetails()
			// 获取派车信息
			this.queryListData();
		},
		// activated() {
		// 	this.orderID = this.$store.getters.getCommonData.id;
		// 	// 获取订单信息
		// 	this.getOrderDetails()
		// 	// 获取派车信息
		// 	this.queryListData();
		// },
		methods: {
			// 获取订单信息
			getOrderDetails() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order/get", {
					id: this.orderID
				}).then(res => {
					this.orderDetails = res.obj;
					this.orderDetails.fPlanAmount = this.orderDetails.fPlanAmount.toFixed(2);
					this.orderDetails.fScheduleVanAmount = this.orderDetails.fScheduleVanAmount.toFixed(2);
					this.orderDetails.fReceivedAmount = this.orderDetails.fReceivedAmount.toFixed(2);
					this.orderDetails.Capac = (this.orderDetails.fPlanAmount - this.orderDetails.fScheduleVanAmount).toFixed(2);
				}).catch(err => {

				})
			},
			// 获取派车信息
			queryListData() {
				this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-list-by-order-id', {
					fOrderId: this.orderID,
					filter: "",
					maxResultCount: this.pagesize,
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(res => {
					console.log('获取派车信息', res.obj.items);
					this.total = res.obj.totalCount;
					this.list = res.obj.items
					if (this.list.length > 0) {
						for (let item of this.list) {
							if (item.fCreateTime) {
								item.fCreateTime = this.getTime(item.fCreateTime)
							}
							if (item.fGrossTime) {
								item.fGrossTime = this.getTime(item.fGrossTime)
							}
							if (item.fTareTime) {
								item.fTareTime = this.getTime(item.fTareTime)
							}
							if (item.fCarrSignTime) {
								item.fCarrSignTime = this.getTime(item.fCarrSignTime)
							}
							if (item.fCustSignTime) {
								item.fCustSignTime = this.getTime(item.fCustSignTime)
							}
							item.fDeliveryAddress = item.fDeliveryAddress.replace(/\\/g, '');
						}

					}
				}, error => {});
			},
			//去订单详情
			toOrderDetails(item) {
				console.log(item.fOrderID);
				this.$router.push({
					name: "OrderDetails",
					query: {
						id: item.fOrderID,
						detailspath: this.$route.path,
						detailsname: this.$route.meta.title
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
			color: themed("themes_color");
			background-color: themed("text_color_white");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.common-top {
		flex: 1;
		height: 138px;
		box-sizing: border-box;
    margin: 10px;
		padding: 0 20px;
		background-color:#ffffff;
	}
  .common-bottom {
    // padding: 0 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
    flex-wrap: wrap;
    padding-bottom: 30px;
    border-bottom: 1px dashed gray;
    margin-top: 10px;
  }
	.common-top1 {
		flex: 1;
		height: 118px;
		box-sizing: border-box;
		padding: 0 15px;
		background-color:#F1FBFE;
	}

	.OrderSendCarDetail {
		max-width: 1100px;
		min-width: 1100px;
    background:#F3F3FF;
		margin: 0 auto;

		.orderID {
			.orderID-title {
				height: 16px;
				line-height: 16px;
			}

			.orderID-content {
				height: 87px;
				align-items: center;

				li {
					text-align: center;
				}
			}
		}

		.orderInfo {
			.orderInfo-left {
				flex: 1;

				.orderInfo-title {
					height: 16px;
					line-height: 16px;
					margin-bottom: 8px;
				}

				li {
					height: 21px;
					line-height: 21px;
				}
			}

			.detailBtn {
				width: 92px;
				height: 32px;
				line-height: 32px;
				border-radius: 2px;
				color: #FFFFFF;
				background-color: #ff6868;
			}
		}
	}
	.hide-text {
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    overflow: hidden;
	}
	.width-505 {
		width: 430px;
	}
	.BorderRadius{
		border-radius: 10px;
	}
</style>
